import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { assetsApi } from 'core/api/assets.api';
import { setAxiosAuth } from 'utils';

export interface AssetsOptionsProps {
  pickupDate: string;
  returnDate: string;
  lat: string;
  lng: string;
  sort?: string;
  assetIds?: string[];
  assetTypeIds?: string[];
  accessToken?: string;
  enabled?: boolean;
}

export const useGetFilterAssetsOptions = ({
  pickupDate,
  returnDate,
  lat,
  lng,
  sort = 'BEST_MATCH',
  assetIds,
  assetTypeIds,
  accessToken = '',
  enabled = true,
}: AssetsOptionsProps) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [
      queryKeys.assetsOptions,
      pickupDate,
      returnDate,
      lat,
      lng,
      sort,
      assetIds,
      assetTypeIds,
    ],
    queryFn: () => assetsApi.getAssetsOptions(
      pickupDate,
      returnDate,
      lat,
      lng,
      sort,
      assetIds,
      assetTypeIds,
    ),
    staleTime: 1000 * 60 * 1,
    enabled: Boolean(accessToken) && enabled,
  });
}

export const useGetMapAssetsOptions = ({
  pickupDate,
  returnDate,
  lat,
  lng,
  accessToken = '',
  enabled = false,
}: AssetsOptionsProps) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [
      queryKeys.assetsOptions,
      pickupDate,
      returnDate,
    ],
    queryFn: () => assetsApi.getAssetsOptions(
      pickupDate,
      returnDate,
      lat,
      lng,
      'BEST_MATCH',
    ),
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(accessToken) && enabled,
  });
}
