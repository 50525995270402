export const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(
  callback: F,
  delay: number,
) => {
  let timeout: ReturnType<typeof setTimeout>;

  return function(...args: Parameters<F>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => { callback.apply(null, args); }, delay);
  }
}
