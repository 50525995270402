/* eslint-disable @typescript-eslint/naming-convention */
import { configureStore } from '@reduxjs/toolkit';

import settingSlice from './slices/settingSlice';

const store = configureStore({
  reducer: {
    setting: settingSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
