import moment from 'moment';
import dayjs from "dayjs";
import { assetsApi } from '../api/assets.api';
import { HOUR_SELECTION_12H } from 'core/constants/constant';
import { formatDouble } from './utils';
import flatbedImage from 'assets/images/trailers-logo/flatbed.png';
import dumpImage from 'assets/images/trailers-logo/dump-trailer-2.png';
import rvImage from 'assets/images/trailers-logo/rv.png';
import enclosedImage from 'assets/images/trailers-logo/enclosed.png';
import gooseneckImage from 'assets/images/trailers-logo/gooseneck.png';
import utilityImage from 'assets/images/trailers-logo/utility.png';

export const getBetweenDay = (startDate: Date, endDate: Date):number | undefined => {
  if (!(startDate instanceof Date) || Number.isNaN(startDate.valueOf())) {
    return;
  }

  if (!(endDate instanceof Date) || Number.isNaN(endDate.valueOf())) {
    return;
  }

  const difference = endDate.getTime() - startDate.getTime();
  const betweenDay = Math.ceil(difference / (1000 * 3600 * 24));

  return betweenDay;
};

export const testValueInAry = (equips: any, field: any, status: any) => {
  let result:boolean = true;

  if (!Array.isArray(equips)) {
    return false;
  }

  equips.forEach((equip) => {
    if (equip[field] !== status) {
      result = false;
    }
  });

  return result;
};

export const isValidDate = (dateString: string, split: string = '/'): string => {
  // First check for the pattern
  const euDate = /^\d{1,2}[\-,\/]\d{1,2}[\-,\/]\d{4}$/.test(dateString);
  if (!euDate) return '';

  // Initialize date parts to avoid 'used before being assigned' error
  let year: number = 0; let month: number = 0;

  let day: number = 0;

  const parts = dateString.split(split);

  if (euDate) {
    month = parseInt(parts[0], 10);
    day = parseInt(parts[1], 10);
    year = parseInt(parts[2], 10);
  }

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return '';

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  if (day > 0 && day <= monthLength[month - 1]) {
    const cMonth = month < 10 ? `0${month}` : `${month}`;
    const cDay = day < 10 ? `0${day}` : `${day}`;
    return [year, cMonth, cDay].join('-');
  }

  return '';
};

export const convertToFt = (length: any) => {
  const feet = Math.floor(length / 12);
  const inches = (length - (feet * 12));
  let str = feet > 0 ? `${String(feet)}'` : '';
  str += (inches > 0 ? `${String(inches)}"` : '');
  return str;
};

export const getDateFromMoment = (date: any) => {
  if (date) return moment(new Date(date)).format('YYYY-MM-DD');
  return '';
};

export const getSimpleDateFromMoment = (pickupDate: any, returnDate: any) => {
  if (pickupDate && returnDate) {
    const pickupMoment = moment(new Date(pickupDate));
    const returnMoment = moment(new Date(returnDate));

    const pickupMonth = pickupMoment.format('MMM');
    const returnMonth = returnMoment.format('MMM');

    if (pickupMonth === returnMonth) {
      return `${pickupMoment.format('MMM D')} - ${returnMoment.format('D')}`;
    }

    return `${pickupMoment.format('MMM D')} - ${returnMoment.format('MMM D')}`;
  }

  return '';
};

export const birthRegx = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}}$/i;
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const checkSessionCartItems = async (cartItems:any, localFilterDate: any, initCartItem = true) => {
  const updatedCartItems = [];

  for (let i = 0; i < cartItems.length; i += 1) {
    const item = { ...cartItems[i] };
    const result = await checkAssetAvailable(item.assetId, getDateFromMoment(localFilterDate?.pickupDate), localFilterDate?.pickupTime, getDateFromMoment(localFilterDate?.returnDate), localFilterDate?.returnTime);

    if (result) {
      updatedCartItems.push(result);
    } else {
      item.unavailable = true;
      if (!initCartItem) updatedCartItems.push(item);
    }
  }

  return updatedCartItems;
};

export const checkAssetAvailable = async (assetId:any, pickupDate:any, pickupTime:any, returnDate:any, returnTime:any) => {
  try {
    const { data } = await assetsApi.checkAssetAvailable(assetId, pickupDate, returnDate, pickupTime || '', returnTime || '');

    if (data && data.status === 'OK') {
      if (data.assets.length > 0) { return data.assets[0]; }

      return false;
    }
  } catch (e) {
    return false;
  }
};

export const errorMessage = (err:any) => {
  let errMessage = 'Some error occured';

  if (typeof err === 'string') {
    errMessage = err;
  }

  if (err) {
    const errData = err?.response?.data;

    if (errData) {
      errMessage = `${errData.status} - ${errData.error}`;
    }
  }

  return errMessage;
};

export const checkPastDate = (dateMoment:any, timeStr:any = '12:00', targetMoment = moment()) => {
  if (!dateMoment || !timeStr || !targetMoment) return false;
  const currentDate = new Date(dateMoment);
  const timeAry = timeStr.split(':');
  currentDate.setHours(parseInt(timeAry[0]));
  currentDate.setMinutes(parseInt(timeAry[1]));
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  return targetMoment >= moment(currentDate);
};

export const getDateMoment = (dateMoment:any, timeStr:any = '12:00') => {
  if (!dateMoment || !timeStr) return moment();
  const currentDate = new Date(dateMoment);
  const timeAry = timeStr.split(':');
  currentDate.setHours(parseInt(timeAry[0]));
  currentDate.setMinutes(parseInt(timeAry[1]));
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  return moment(currentDate);
};

export const lazyRetry = async <T>(componentImport: () => Promise<T>) => new Promise<T>((resolve, reject) => {
  const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
  const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) ?? 'false');

  componentImport()
    .then((component) => {
      window.sessionStorage.setItem(storageKey, 'false');

      if (component === undefined) {
        window.sessionStorage.setItem(storageKey, 'true');
        window.location.reload(); return; // refresh the page
      }

      resolve(component);
    })
    .catch((error) => {
      if (!hasRefreshed) {
        // not been refreshed yet
        window.sessionStorage.setItem(storageKey, 'true');
        window.location.reload();
      }

      reject(error); // Default error behavior as already tried refresh
    });
});

export const makeCapital = (str: string) => {
  const finalStr = str
    .replace(/(_|-)/g, ' ')
    .trim().replace(
      /\w\S*/g,
      function cap(temp:string) {
        return temp.charAt(0).toUpperCase() + temp.substr(1);
      },
    )
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

  return finalStr;
};

export const getOwnerName = (str: string) => {
  if (str) {
    const ownerNameArray = str?.split(' ');

    let ownerName = String(ownerNameArray[0].charAt(0).toUpperCase())
    + String(ownerNameArray[0].substr(1));

    if (ownerNameArray.length > 1) {
      ownerName = String(ownerName) + String(' ') + String(ownerNameArray[1].charAt(0).toUpperCase());
    }

    return ownerName;
  }

  return '-';
};

export const getSelectedOption = (val:any, options:any) => {
  let selOption = { label: "", value: "" , lookup:""};
  if (typeof val === "number") val = val + "";
  if (val && val?.toString().trim().length > 0) {
    selOption = options?.find(
      (option:any) =>
        option.value.toString().trim().toLowerCase() ===
        val?.toString().trim().toLowerCase()
    );
  }
  return selOption;
};

export const isTimeInRange = (time:any , hoursMap:any) => {
	let valid = false;
	if(!time || !hoursMap)
		return valid;
	if(!time.includes(":"))
		time = time.slice(0, 2) + ":" + time.slice(2);
	hoursMap.forEach((hour: any) => {
		if(hour?.close >= time && hour?.open <= time)
			valid= true;
	});

	return valid;
};


export const getEnableTimes = (date: any,hoursMap: any ) => {
    const newPickupTimes: any = [];
    HOUR_SELECTION_12H.forEach((time) => {
      const timeItem = { ...time };
      const timeAry = timeItem.value.match(/.{1,2}/g) || ["00", "00"];
      const hour =
        parseInt(timeAry[0]) < 13
          ? parseInt(timeAry[0])
          : parseInt(timeAry[0]) - 12 < 10
          ? `0${parseInt(timeAry[0]) - 12}`
          : parseInt(timeAry[0]) - 12;
      timeItem["value"] = `${timeAry[0]}:${timeAry[1]}`;
      timeItem["label"] = `${hour}:${timeAry[1]} ${
        parseInt(timeAry[0]) < 12 ? "AM" : "PM"
      }`;
      const twoHLaterTime = parseInt(time.value) - 200;
      const twoHLaterTimeStr = twoHLaterTime < 1000 ? '0' + twoHLaterTime.toString() : twoHLaterTime.toString();
      const twoHLaterTime2 = parseInt(time.value) + 200;
      const twoHLaterTimeStr2 = twoHLaterTime2 < 1000 ? '0' + twoHLaterTime2.toString() : twoHLaterTime2.toString();

      if (testDisableTime(date, time.value, hoursMap,)) {
        timeItem["disabled"] = true;
        if (!testDisableTime(date, twoHLaterTimeStr, hoursMap) ||
        	!testDisableTime(date, twoHLaterTimeStr2, hoursMap)){
          newPickupTimes.push(timeItem);
        }
      } else {
        newPickupTimes.push(timeItem);
      }
    });
    return newPickupTimes;
  };

export const testDisableTime = (date:any, time:any, hoursMap:any) => {
    if (date === "") return;
    const weekDays = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];

    const cDate = new Date(date);
    const day = cDate.getDay();

    let disable = false;
    //check past time if today date
    const todayDate = dayjs().format("MM/DD/YYYY");
	if(todayDate === dayjs(date).format("MM/DD/YYYY")){
		const todayTime = dayjs().format("HHmm");
		if(!time || todayTime > time)
			disable= true;
	}
    if (!disable && hoursMap) {
      const weekHoursMap = hoursMap.STAFF;

      if (weekHoursMap) {
        const dayHoursMap = weekHoursMap[weekDays[day]];
        if (dayHoursMap){
			disable = true;
			if (!dayHoursMap.closed && 
				(!dayHoursMap.hours || dayHoursMap.hours.length === 0 ||
        		isTimeInRange(time,dayHoursMap.hours))) {
          			disable = false;
          	}
        }
      }
    }

    return disable;
};


const generateProductDescription = (product: any) => {
  const { name, type, make, model, capacity, weight, feature, rate } = product;

  let description = `${name} (${make} ${model}) is a ${type.name.toLowerCase()} trailer. `;

  if (capacity) {
    description += `It has a hauling capacity of ${capacity} lbs and weighs ${weight} lbs. `;
  }

  if (feature) {
    description += `This trailer comes with a ${feature.toLowerCase()} feature. `;
  }

  // Include the rate if available
  if (rate && rate.dayRateAvailable && rate.dayRate) {
    description += `It is available for rent at $${rate.dayRate} per day. `;
  }

  description += "Perfect for heavy-duty transportation, car hauling, or equipment transport.";

  return description;
};


export const generateProductStructuredData = (products:any) => {
  const category = 'trailer-rentals';
  return {
    "@context": "https://schema.org",
    "@graph": products.map((product: any) => ({
      "@type": "Product",
      "name": product.name,
      "description": generateProductDescription(product),
      "category": `${product.type.name} ${product.type.type}`,
      "url": window.location.origin + `/${category}/${product?.type?.name?.toLowerCase()||""}-trailers/${product.assetId}`,
      "availableAtOrFrom": {
        "@type": "Place",
        "name": product.location.name,
        "address": {
          "@type": "PostalAddress",
          "addressLocality": product.location.city,
          "addressRegion": product.location.state,
          "addressCountry": "US"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": product.location.lat,
          "longitude": product.location.lng
        }
      }
    }))
  };
};

export const generateProductDetailStructuredData = ( product: any, equipRate: any) => {
  const category = 'trailer-rentals';
  const subCategory = `${product?.type?.name?.toLowerCase()||""}-trailers`;
  const url = `${window.location.origin}/${category}/${subCategory}/${product.assetId}`;
  const brandName = "Big Rentals";
  return {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": product.name,
    "description": generateProductDescription(product),
    "brand": {
      "@type": "Brand",
      "name": brandName
    },
    "url": url,
    "offers": [
    {
      "@type": "Offer",
      "price": equipRate?.dayRate ? `${formatDouble(equipRate.dayRate)}` : '-',
      "priceCurrency": "USD",
      "availability": "http://schema.org/InStock",
      "eligibleDuration": {
        "@type": "QuantitativeValue",
        "value": 1,
        "unitCode": "DAY"
      },
      "url": url,
      "offeredBy": {
        "@type": "Organization",
        "name": brandName
      }
    },
    {
      "@type": "Offer",
      "price": equipRate?.weekRate ? `${formatDouble(equipRate.weekRate)}` : '-',
      "priceCurrency": "USD",
      "availability": "http://schema.org/InStock",
      "eligibleDuration": {
        "@type": "QuantitativeValue",
        "value": 1,
        "unitCode": "WEEK"
      },
      "url": url,
      "offeredBy": {
        "@type": "Organization",
        "name": brandName
      }
    },
    {
      "@type": "Offer",
      "price":   equipRate?.monthRate ? `${formatDouble(equipRate.monthRate)}` : '-',
      "priceCurrency": "USD",
      "availability": "http://schema.org/InStock",
      "eligibleDuration": {
        "@type": "QuantitativeValue",
        "value": 1,
        "unitCode": "MON"
      },
      "url": url,
      "offeredBy": {
        "@type": "Organization",
        "name": brandName
      }
    }
  ],
    "availableAtOrFrom": {
      "@type": "Place",
      "name": product.location.name,
      "address": {
        "@type": "PostalAddress",
        "addressLocality": product.location.city,
        "addressRegion": product.location.state,
        "addressCountry": "US"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": product.location.lat,
        "longitude": product.location.lng
      }
    }
  };
};

export const getImageForAssetType = (typeName: string): string => {


  const assetTypeToImageMap: { [key: string]: string } = {
    'Flatbed': flatbedImage,
    'Deckover': flatbedImage,
    'Tilt Deck': flatbedImage,
    'TiltDeck': flatbedImage,
    'Tilt': flatbedImage,
    'Dump': dumpImage,
    'RV': rvImage,
    'Enclosed': enclosedImage,
    'Gooseneck': gooseneckImage,
    'Utility': utilityImage,
  };

  return assetTypeToImageMap[typeName] || flatbedImage;
};
export function setCookie(name: string, value: string, days: number) {
  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}

export function getCookie(name:string) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}