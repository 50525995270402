import type { ReactElement, ReactNode } from 'react';
import React, { useState, useEffect, createContext, useCallback, useMemo } from 'react';
import { debounce } from 'utils';
import {DEFAULT_MAP_ZOOM} from 'core/constants/constant';

interface SettingContextData {
  location: any;
  setLocation: React.Dispatch<React.SetStateAction<any>>;
  debouncedSetLocation: React.Dispatch<React.SetStateAction<any>>;
  locationZoom: number;
  setLocationZoom: React.Dispatch<React.SetStateAction<any>>;
  clientInfo: any;
  setClientInfo: React.Dispatch<React.SetStateAction<any>>;
  showTopHeader: boolean;
  setShowTopHeader: React.Dispatch<React.SetStateAction<boolean>>;
  currentCity: string;
  setCurrentCity: React.Dispatch<React.SetStateAction<string>>;
  debouncedSetCurrentCity: React.Dispatch<React.SetStateAction<string>>;
  mProductShow: boolean;
  setMProductShow: React.Dispatch<React.SetStateAction<boolean>>;
  mProductShowImg: boolean;
  setMProductShowImg: React.Dispatch<React.SetStateAction<boolean>>;
  mProductShowDetail: any;
  setMPRoductShowDetail: React.Dispatch<React.SetStateAction<any>>;
  mProductIndex: number;
  setMProductIndex: React.Dispatch<React.SetStateAction<number>>;
  activeGradient: boolean;
  setActiveGradient: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SettingContext = createContext<SettingContextData>({
  location: {},
  setLocation: () => {},
  debouncedSetLocation: () => {},
  locationZoom: 9,
  setLocationZoom: () => {},
  clientInfo: {},
  setClientInfo: () => {},
  showTopHeader: true,
  setShowTopHeader: () => {},
  currentCity: '',
  setCurrentCity: () => {},
  debouncedSetCurrentCity: () => {},
  mProductShow: false,
  setMProductShow: () => {},
  mProductShowImg: false,
  setMProductShowImg: () => {},
  mProductShowDetail: false,
  setMPRoductShowDetail: () => {},
  mProductIndex: -1,
  setMProductIndex: () => {},
  activeGradient : false,
  setActiveGradient : () => {},
});

export interface ISettingProviderProperties {
  children: ReactNode;
}

function SettingProvider({ children }: ISettingProviderProperties):ReactElement {
  const [showTopHeader, setShowTopHeader] = useState(true);
  const [clientInfo, setClientInfo] = useState({});
  const [currentCity, setCurrentCity] = useState<string>('');
  const [locationZoom, setLocationZoom] = useState<number>(DEFAULT_MAP_ZOOM);
  const [mProductShow, setMProductShow] = useState(false);
  const [mProductShowImg, setMProductShowImg] = useState(false);
  const [mProductShowDetail, setMPRoductShowDetail] = useState({});
  const [mProductIndex, setMProductIndex] = useState(0);
  const [activeGradient, setActiveGradient] = useState(false);

  const [location, setLocation] = useState(() => {
    const storedLocation = localStorage.getItem('Location');
    return storedLocation ? JSON.parse(storedLocation) : {};
  });

  const debouncedSetLocation = useMemo(() => debounce(setLocation, 333), []) ;

  const debouncedSetCurrentCity = useMemo(() => debounce(setCurrentCity, 333), []) ;

  useEffect(() => {
    localStorage.setItem('Location', JSON.stringify(location));
  }, [location]);

  const contextData: SettingContextData = {
    location,
    setLocation,
    debouncedSetLocation,
    locationZoom,
    setLocationZoom,
    clientInfo,
    setClientInfo,
    showTopHeader,
    setShowTopHeader,
    currentCity,
    setCurrentCity,
    debouncedSetCurrentCity,
    mProductShow,
    setMProductShow,
    mProductShowImg,
    setMProductShowImg,
    mProductShowDetail,
    setMPRoductShowDetail,
    mProductIndex,
    setMProductIndex,
    activeGradient,
    setActiveGradient,
  };

  return (
    <SettingContext.Provider value={contextData}>
      {children}
    </SettingContext.Provider>
  );
}

export default SettingProvider;
