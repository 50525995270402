/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import type moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import type { equipItemProp2 } from '../../interfaces/base';

type filterDateType = {
  pickupDate?: moment.Moment;
  pickupTime?: any;
  returnDate?: moment.Moment;
  returnTime?: any;
};

type initialStateType = {
  headerTheme: string;
  showInfoModal: boolean;
  filterDate: filterDateType | null;
  filterCategory: string;
  equipInfo: any;
  cartItems: equipItemProp2[];
  equipFee: {
    rentalFinalTotal?: number;
  };
  equipRate: {
    rentalFinalTotal?: number;
  };
  partnerInfo: {
    config?: any;
  };
  partnerStore: {
    id?: string;
  }
  checkoutForm: {
    fName?: string;
    customerEmail?: string;
    terms?: boolean;
  },
  customerCheckId: number[] | string [];
  coValidation: {
    customerNameFirst?: boolean;
    terms?: boolean;
  },
  customerId: number,
  customerAddId: number,
  bookingId: number,
  isLoading: boolean;
  locations: { label: string, value: string }[];
}

const initialState: initialStateType = {
  headerTheme: 'white',
  showInfoModal: false,
  filterDate: {},
  filterCategory: '',
  equipInfo: {},
  equipRate: {},
  equipFee: {},
  cartItems: [],
  partnerInfo: {},
  partnerStore: {},
  checkoutForm: {},
  customerCheckId: ['1'],
  customerId: 0,
  customerAddId: 0,
  bookingId: 0,
  coValidation: {},
  isLoading: false,
  locations: [],
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setHeaderTheme: (state, { payload }) => {
      state.headerTheme = payload;
    },
    setShowInfoModal: (state, { payload }) => {
      state.showInfoModal = payload;
    },
    setEquipInfo: (state, { payload }) => {
      state.equipInfo = payload;
    },
    setAddCart: (state, { payload }) => {
      state.cartItems = [payload];
    },
    setCartItems: (state, { payload }) => {
      state.cartItems = payload;
    },
    setFilterDate: (state, { payload }) => {
      state.filterDate = payload;
    },
    setFilterCategory: (state, { payload }) => {
      state.filterCategory = payload;
    },
    setRemoveCart: (state, { payload }) => {
      state.cartItems = [];
      state.equipInfo = {};
      state.equipRate = {};
      state.equipFee = {};
    },
    setEquipRate: (state, { payload }) => {
      state.equipRate = payload;
    },
    setEquipFee: (state, { payload }) => {
      state.equipFee = payload;
    },
    setCheckoutForm: (state, { payload }) => {
      state.checkoutForm = payload;
    },
    setPartnerInfo: (state, { payload }) => {
      state.partnerInfo = payload;
    },
    setPartnerUrl: (state, { payload }) => {
      state.partnerStore = payload;
    },
    setCustomerCheckId: (state, { payload }) => {
      state.customerCheckId = payload;
    },
    setCOValidation: (state, { payload }) => {
      state.coValidation = payload;
    },
    setCustomerId: (state, { payload }) => {
      state.customerId = payload;
    },
    setCustomerAddId: (state, { payload }) => {
      state.customerAddId = payload;
    },
    setBookingId: (state, { payload }) => {
      state.bookingId = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setLocations: (state, { payload }) => {
      state.locations = payload;
    },
  },
});

export const {
  setHeaderTheme,
  setShowInfoModal,
  setEquipInfo,
  setEquipRate,
  setEquipFee,
  setAddCart,
  setCartItems,
  setRemoveCart,
  setFilterDate,
  setFilterCategory,
  setPartnerInfo,
  setPartnerUrl,
  setCheckoutForm,
  setCustomerCheckId,
  setCOValidation,
  setCustomerId,
  setCustomerAddId,
  setBookingId,
  setIsLoading,
  setLocations,
} = settingSlice.actions;
export default settingSlice.reducer;
