/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const formatDouble = (value: any, digit = 0) => {
  if (value) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    });
  }

  return '0.00';
};

export const formatNumber = (value: any) => {
  if (value) return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
  return '0';
};

// Get User Agent
export const getUserAgent = (context: any) => {
  const UA = context.req.headers['user-agent'];

  const isMobile = Boolean(
    UA.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ),
  );

  return isMobile;
};
