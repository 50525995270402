import { useQuery } from '@tanstack/react-query';
import httpService from 'core/api/httpService';
import { queryKeys } from '../queryKeys';

export const useGetAccessToken = () => useQuery({
  queryKey: [queryKeys.accessToken],
  queryFn: httpService.getAuthToken,
  refetchOnWindowFocus: 'always',
  refetchOnReconnect: 'always',
  staleTime: 1000 * 60 * 4,
  retry: true,
  retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 1000 * 15),
});
