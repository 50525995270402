import axios from 'axios';
import http from './httpService';

class CommonApi {
  headers = {
    'accept': 'application/json, text/plain, */*',
    'content-type': 'application/json',
  };

  async getIPFromAmazon() {
    return await http.get('https://checkip.amazonaws.com/');
  }

  async getClientIPInfo() {
    const headers1 = {
      'Origin': 'https://ipinfo.io/json',
    };

    const instance = axios.create();
    delete instance.defaults.headers.common.Authorization;
    delete instance.defaults.headers.common['RD-TenantID'];
    delete instance.defaults.headers.common.apiCallerSource;

    return instance.get('https://ipinfo.io/json', {
      headers: headers1,
    });
  }

  async getPortalInfo() {
    const { data } = await this.getIPFromAmazon();
    const query = window.location.search;
    var path = window.location.pathname;
    path = path.length === 0 ? '/' : path;

    const headers1 = {
      "portalpath": path,
      'X-Remote-Addr': data.replace('\n', '').trim(),
    };

    return http.get(`${process.env.REACT_APP_BASE_URL ?? ''}portal/info${query}`, {
      headers: headers1,
    });
  }

  async getPartnerLogo() {
    return http.get(`${process.env.REACT_APP_BASE_URL ?? ''}partner/logo`);
  }

  async getPartnerUrl() {
    return http.get(`${process.env.REACT_APP_BASE_URL ?? ''}partner/store`);
  }

  async updateCustomer(data: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL ?? ''}customer`, JSON.stringify(data), {
      headers: this.headers,
    });
  }

  async lookupCustomer(birthday:any, firstName: any, lastName: any, email: any, phone: any) {
    return http.get(`${process.env.REACT_APP_BASE_URL ?? ''}customer/lookup?birthday=${birthday}&firstName=${firstName}&lastName=${lastName}&email=${email}&phone=${phone}`);
  }

  async getCustomerInfo(customerId: any) {
    return http.get(`${process.env.REACT_APP_BASE_URL ?? ''}customer/${customerId}`);
  }

  async getBookingInfo(bookingId: any) {
    return http.get(`${process.env.REACT_APP_BASE_URL ?? ''}booking/${bookingId}`);
  }

  async updateBooking(data:any) {
    return http.post(`${process.env.REACT_APP_BASE_URL ?? ''}booking`, JSON.stringify(data), {
      headers: this.headers,
    });
  }

  async setBillMethodDefault(data: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL ?? ''}bill/method`, JSON.stringify(data), {
      headers: this.headers,
    });
  }
}

export const commonApi = new CommonApi();
