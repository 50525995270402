
export const addTrackingCode = (env) => {
  if(undefined !== env && env.startsWith('prod') ){
	  //added on 23Sep
	  const script = document.createElement("noscript");
	  script.innerHTML = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-575QFC4G' height='0' width='0' style='display:none;visibility:hidden'></iframe>";
	  document.head.appendChild(script);
	  
	  //added on 23Sep
	  const script1 = document.createElement("script");
	  script1.innerHTML ="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-575QFC4G');";
	  document.head.appendChild(script1);
	  
	  //old
	  const script2 = document.createElement("script");
	  script2.src = "https://www.googletagmanager.com/gtag/js?id=G-2VC15ZKZ49";
	  script2.async = true;
	  document.head.appendChild(script2);
	  
	  //old
	  const script3 = document.createElement("script");
	  script3.innerHTML ="window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-2VC15ZKZ49');";
	  document.head.appendChild(script3);
	  
  }
}

export const triggerConfirmEvent = (env, bookingId, total) => {
  if(undefined !== env && env.startsWith('prod') ){
	  const script1 = document.createElement("script");
	  
	  script1.innerHTML ="gtag('event', 'conversion', {'send_to': 'AW-16548202652/AGQyCMKqkNUZEJyR5tI9','value': "+total+",'currency': 'USD','transaction_id': "+bookingId+"});";
	  document.head.appendChild(script1);
  }
}