import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { commonApi } from 'core/api/common.api';
import { setAxiosAuth } from 'utils';

export const useGetPortalInfo = ({ accessToken = '' }) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [queryKeys.portalInfo],
    queryFn: async () => await commonApi.getPortalInfo(),
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(accessToken),
  });
}
